<template>
  <div class="q-pa-md">
    <q-card>
      <q-card-section class="row border-bottom items-center full-width q-py-xs q-pl-none">
        <q-legend
            :label="$t('Users')"
            text-class="text-h6"
        />

        <search
            dense
            autoset
            is-expandable
            @submit="handleSearch"
        />

        <q-space/>

        <q-btn
            :color="serverParams.filter && serverParams.filter.length > 0 ? 'light-blue-9' : 'dark'"
            text-color="white"
            size="sm"
            class="q-mr-sm"
            :label="filterBtnText"
            no-caps
            unelevated
            @click="openCloseFilters"
        />

        <q-btn
            color="dark"
            text-color="white"
            :label="$t('Refresh')"
            size="sm"
            class="q-mr-sm"
            no-caps
            unelevated
            @click="refreshItems"
        />

        <q-btn-dropdown
            v-if="isAdministrator || isSupervisior"
            color="light-blue-9"
            text-color="white"
            class="q-mr-sm"
            no-caps
            unelevated
            size="sm"
            icon="add"
        >
          <q-list>
            <q-item clickable v-close-popup @click="create('client')">
              <q-item-section>
                <q-item-label>
                  {{ 'Customer Portal' }}
                </q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="create('employee')">
              <q-item-section>
                <q-item-label>
                  {{ 'Employee' }}
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </q-card-section>

      <filter-collapse
          :is-open="isOpen"
          :options="{
          defaultFilter: serverParams.filter,
          fields: activatedFields,
          values: {
            states: statuses
          },
          style: {
            noGroups: true
          }
        }"
          @submit="handleFiltersSubmit"
          @close="openCloseFilters"
      />

      <q-card-section class="row q-ma-none q-pa-none">
        <status-filter
            class="hide-on-mobile"
            :title="'Types'"
            :outside-selected-status="selectedTypes"
            :statuses="typeMatrix"
            @on-change="onTypeChange"
        />

        <div class="col">
          <q-table
              style="height: calc(100vh - 130px);"
              class="sticky-header-table"
              row-key="id"
              :rows-per-page-label="$t('Rows per page')"
              :rows="users"
              :columns="columns"
              v-model:pagination="pagination"
              :loading="usersLoading"
              :filter="filter"
              virtual-scroll
              binary-state-sort
              flat
              @request="onRequest"
          >
            <template v-slot:loading>
              <q-inner-loading
                  showing
                  color="primary"
              />
            </template>

            <template v-slot:body="props">
              <q-tr
                  :props="props"
                  class="clickable"
                  @dblclick="onRowDblClick(props.row)"
              >
                <q-td
                    key="id"
                    :props="props"
                    auto-width
                >
                  {{ props.row.id }}
                </q-td>

                <q-td
                    key="name"
                    :props="props"
                >
                  {{ props.row.name }}
                </q-td>

                <q-td
                    key="username"
                    :props="props"
                    auto-width
                >
                  {{ props.row.username }}
                </q-td>

                <q-td
                    key="email"
                    :props="props"
                >
                  {{ props.row.email }}
                </q-td>

                <q-td
                    key="parent"
                    :props="props"
                    auto-width
                >
                  <div v-if="props.row._embedded && props.row._embedded.parent">
                    {{
                      props.row._embedded.parent.name || props.row._embedded.parent.username || props.row._embedded.parent.email
                    }}
                    ({{ props.row._embedded.parent.id }})
                  </div>
                </q-td>

                <q-td
                    key="domain"
                    :props="props"
                    auto-width
                >
                  {{ props.row.domain }}
                </q-td>

                <q-td
                    key="created"
                    :props="props"
                    auto-width
                >
                  {{ $moment(props.row.created.date).format(appOptions.formats.date) }}
                </q-td>

                <q-td
                    key="state"
                    :props="props"
                    auto-width
                >
                  <q-toggle
                      color="light-blue-9"
                      :model-value="props.row.state === 'active'"
                      @update:model-value="handleStateChange(props.row)"
                  />
                </q-td>
              </q-tr>
            </template>
          </q-table>
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Components
import Search from '../../components/search/Search.vue'
import FilterCollapse from '../../components/filters/FilterCollapse.vue'

// Utils
import { buildQuery } from '../../utils/query-utils'

// Mixins
import TableMixin from './../../components/global/TableMixin'
import StatusFilter from '@/apps/app/components/filters/StatusFilter.vue'
import userTypesMatrix from './../../config/UserTypesMatrix'

export default {
  name: 'Users',
  components: {
    StatusFilter,
    Search,
    FilterCollapse
  },
  mixins: [
    TableMixin
  ],
  data() {
    return {
      isOpen: false,
      filter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      columns: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'left'
        },
        {
          label: this.$t('Username'),
          name: 'username',
          align: 'left'
        },
        {
          label: this.$t('Email'),
          name: 'email',
          align: 'left'
        },
        {
          label: this.$t('Parent'),
          name: 'parent',
          align: 'left'
        },
        {
          label: this.$t('Domain'),
          name: 'domain',
          align: 'left'
        },
        {
          label: this.$t('Created'),
          name: 'created',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left'
        }
      ],
      stateColors: {
        normal: 'success',
        blocked: 'dark'
      },
      statuses: [
        { id: 'normal', title: 'Normal' },
        { id: 'blocked', title: 'Blocked' }
      ],
      activatedFields: [
        'state',
        'id',
        'created.from',
        'created.to',
        'name',
        'username',
        'email',
        'domain'
      ],
      typeMatrix: userTypesMatrix,
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'users',
      'totalUsersNumber',
      'usersPage',
      'usersLoading',
      'storagePlaces',
      'appOptions',
      'isSupervisior',
      'isAdministrator'
    ]),
    filterBtnText() {
      return this.serverParams.filter && this.serverParams.filter.length > 0
          ? this.$t('Filtered: ') + this.totalUsersNumber
          : this.$t('Filter')
    },
    selectedTypes() {
      const types = (this.serverParams.filter || []).find(filter => {
        return filter.type === 'instanceof'
      })

      if (types && types.class) {
        return [
          types.class,
        ]
      }

      return types
          ? [types.value]
          : []
    },
  },
  mounted() {
    this.loadDefaultItems()
  },
  methods: {
    ...mapActions([
      'loadUsers'
    ]),
    ...mapMutations([
      'setUser',
      'upsertUser'
    ]),
    refreshItems() {
      return this.onRequest({
        pagination: {
          forceReload: true
        }
      })
    },
    handleStateChange(item) {
      const invertedState = {
        active: 'inactive',
        inactive: 'active'
      }

      const state = invertedState[item.state]

      return this.$service.user.save({ state }, item.id)
          .then(item => {
            this.upsertUser(item)
          })
    },
    handleFiltersSubmit(filter) {
      this.isOpen = false
      this.onRequest({ pagination: { filter, page: 1 } })
    },
    openCloseFilters() {
      this.isOpen = !this.isOpen
    },
    loadDefaultItems() {
      return this.onRequest({ pagination: {} })
    },
    onRequest(data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      if (query.search && query.search[query.search.length - 1] !== '*' && query.search[query.search.length - 2] !== ':' && !query.search.includes('%')) {
        query.search += query.search[query.search.length - 1] === ':'
            ? '*'
            : ':*'
      }

      this.updateParams(query)

      return this.loadUsers(this.serverParams)
          .then(({ items, totalPages, page, totalItems }) => {
            this.pagination = {
              ...this.pagination,
              page,
              rowsNumber: totalItems
            }

            return { items, totalPages, page, totalItems }
          })
    },
    handleSearch(search) {
      return this.onRequest({ pagination: { page: 1, search } })
    },
    create(type) {
      this.$router.push(`/customer-portals/entity${type ? `?type=${type}` : ''}`)
    },
    onRowDblClick(row) {
      this.setUser(row)
      this.$router.push('/customer-portals/entity/' + row.id)
    },
    onTypeChange(data) {
      let filter = this.serverParams.filter || []

      filter = filter.filter(item => item.type !== 'instanceof')

      if (data.length > 0) {
        filter.push({ type: 'instanceof', alias: 'u', class: data[0] })
      }

      return this.onRequest({ pagination: { per_page: 25, page: 1, filter } })
    },
  }
}
</script>
